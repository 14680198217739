<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 justify-content-center">
      <div class="tbb-bg-image">
        <b-img
          :src="bgImage"
          alt="BeautiBook - Login"
        />
      </div>

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <tbb-logotype />
        <h2 class="text-white tbb-logo mb-0">
          <span class="font-weight-light">Beauti</span>Book
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center"
      >
        <div class="mx-auto text-center mw-350">
          <b-spinner
            label="Loading..."
            variant="primary"
          />
        </div>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BImg, BLink, BSpinner,
} from 'bootstrap-vue'
import { BOOKING_SECOND_STEP_PAGE } from '@/router/routes/routes-names'
import TbbLogotype from '../../sites/TbbLogotype.vue'
import TbbBg from '../../../assets/images/pages/tbb-bg.jpg'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BLink,
    BSpinner,
    TbbLogotype,
  },

  data() {
    return {
      logging: false,
      bgImage: TbbBg,
    }
  },

  watch: {
    '$auth.loading': function authLoadWatcher(loading) {
      if (!loading) {
        this.authLibLoaded()
      }
    },
  },

  created() {
    this.$authCustomer.initClient().then(() => {
      if (!this.$auth.loading) {
        this.authLibLoaded()
      }
    })
  },

  methods: {
    async authLibLoaded() {
      try {
        const sessionReturnToJson = window.sessionStorage.getItem('returnTo')
        const returnTo = (sessionReturnToJson) ? JSON.parse(sessionReturnToJson) : { name: BOOKING_SECOND_STEP_PAGE }

        window.sessionStorage.removeItem('returnTo')

        this.$router.push(returnTo)
      } catch (e) {
        this.$auth.logout()
        return false
      }

      return true
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import 'src/assets/scss/elements/login-page.scss';
</style>
